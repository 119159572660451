<template>

    <!-- begin:popup-container -->
    <div class="popup-container" :class="{ 'is-active': isActive }">
      <!-- popup -->
      <div class="popup" id="popup-facility-k02">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">학습상세</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="kb-table kb-table-bordered kb-table-bordered-min">
                <table>
                  <colgroup>
                    <col style="width:auto" />
                    <col style="width:200px" />
                    <col style="width:150px" />
                    <col style="width:150px" />
                    <col style="width:150px" />
                    <col style="width:150px" />
                  </colgroup>
                  <thead>
                  <tr>
                    <th><span class="th-title">항목</span></th>
                    <th><span class="th-title">직군</span></th>
                    <th><span class="th-title">최대점수</span></th>
                    <th><span class="th-title">배점</span></th>
                    <th><span class="th-title">점수</span></th>
                    <th><span class="th-title">건수</span></th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length>0">
                    <tr v-for="(item) in items" :key="item?.newzClssCd">
                      <td><strong class="td-text">{{ item?.clssNm }}</strong></td>
                      <td><strong class="td-text">{{ item?.jikgunNm }}</strong></td>
                      <td><strong class="td-text">{{ item?.maxScore }} 점</strong></td>
                      <td><strong class="td-text">{{ item?.alloScore }} 점</strong></td>
                      <td><strong class="td-text">{{ item?.score }} 점</strong></td>
                      <td><strong class="td-text">{{ item?.cnt }}건</strong></td>    
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6"><strong class="td-text">검색결과가 없습니다.</strong></td>
                    
                    </tr>
                  </tbody>                  
                </table>
              </div>
            </div>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button type="button" @click="fnCloseModal" class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->



</template>

<script setup>
import {ref ,defineExpose} from 'vue';

import {useStore} from 'vuex';
import {
  getItems,
  lengthCheck,
  
} from "@/assets/js/util";

import {ACT_GET_TOT_LRNER_DTL_SCORE} from '@/store/modules/newzonboarding/newz';
const items  = ref([]);
const isActive = ref(false);
const store = useStore();
const vParams = ref({
  newzClssCd : null,
  distCrseSn : null,  
});

const getTotLrnerDtlScore = () => {
    store.dispatch(`newz/${ACT_GET_TOT_LRNER_DTL_SCORE}`, vParams.value).then(res => {
      if (lengthCheck(res)) {         
        items.value = getItems(res);

      } else {
        items.value = [];
      }
    }).catch(e => {
      items.value = [];
      console.error(e);
    })
}

const fnShowModal = (params) =>{
   vParams.value.newzClssCd  = params.newzClssCd;
   vParams.value.distCrseSn  = params.distCrseSn;
   
   getTotLrnerDtlScore();
   isActive.value = true;
}

const fnCloseModal = () =>{   
   isActive.value = false;
}

defineExpose({ fnShowModal});
</script>
